/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ServiceEndpointProtocol,
    ServiceEndpointProtocolFromJSON,
    ServiceEndpointProtocolFromJSONTyped,
    ServiceEndpointProtocolToJSON,
} from './ServiceEndpointProtocol';
import {
    ServiceEndpointType,
    ServiceEndpointTypeFromJSON,
    ServiceEndpointTypeFromJSONTyped,
    ServiceEndpointTypeToJSON,
} from './ServiceEndpointType';

/**
 * 
 * @export
 * @interface ServiceEndpoint
 */
export interface ServiceEndpoint {
    /**
     * 
     * @type {number}
     * @memberof ServiceEndpoint
     */
    port: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceEndpoint
     */
    pathPrefix?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceEndpoint
     */
    pathPrefixAware?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ServiceEndpoint
     */
    path?: string;
    /**
     * 
     * @type {ServiceEndpointProtocol}
     * @memberof ServiceEndpoint
     */
    protocol: ServiceEndpointProtocol;
    /**
     * 
     * @type {ServiceEndpointType}
     * @memberof ServiceEndpoint
     */
    type?: ServiceEndpointType;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceEndpoint
     */
    isExposed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceEndpoint
     */
    isSecured?: boolean;
}

export function ServiceEndpointFromJSON(json: any): ServiceEndpoint {
    return ServiceEndpointFromJSONTyped(json, false);
}

export function ServiceEndpointFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceEndpoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'port': json['port'],
        'pathPrefix': !exists(json, 'pathPrefix') ? undefined : json['pathPrefix'],
        'pathPrefixAware': !exists(json, 'pathPrefixAware') ? undefined : json['pathPrefixAware'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'protocol': ServiceEndpointProtocolFromJSON(json['protocol']),
        'type': !exists(json, 'type') ? undefined : ServiceEndpointTypeFromJSON(json['type']),
        'isExposed': !exists(json, 'isExposed') ? undefined : json['isExposed'],
        'isSecured': !exists(json, 'isSecured') ? undefined : json['isSecured'],
    };
}

export function ServiceEndpointToJSON(value?: ServiceEndpoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'port': value.port,
        'pathPrefix': value.pathPrefix,
        'pathPrefixAware': value.pathPrefixAware,
        'path': value.path,
        'protocol': ServiceEndpointProtocolToJSON(value.protocol),
        'type': ServiceEndpointTypeToJSON(value.type),
        'isExposed': value.isExposed,
        'isSecured': value.isSecured,
    };
}

