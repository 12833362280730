/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceMetricsResponse
 */
export interface ServiceMetricsResponse {
    /**
     * 
     * @type {number}
     * @memberof ServiceMetricsResponse
     */
    count: number;
}

export function ServiceMetricsResponseFromJSON(json: any): ServiceMetricsResponse {
    return ServiceMetricsResponseFromJSONTyped(json, false);
}

export function ServiceMetricsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceMetricsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
    };
}

export function ServiceMetricsResponseToJSON(value?: ServiceMetricsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
    };
}

