/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ServiceMetricsResponse,
    ServiceMetricsResponseFromJSON,
    ServiceMetricsResponseFromJSONTyped,
    ServiceMetricsResponseToJSON,
} from './ServiceMetricsResponse';

/**
 * 
 * @export
 * @interface ServiceMetricsRangeResponseMetrics
 */
export interface ServiceMetricsRangeResponseMetrics {
    /**
     * Unix timestamp
     * @type {number}
     * @memberof ServiceMetricsRangeResponseMetrics
     */
    timestamp: number;
    /**
     * 
     * @type {ServiceMetricsResponse}
     * @memberof ServiceMetricsRangeResponseMetrics
     */
    element: ServiceMetricsResponse;
}

export function ServiceMetricsRangeResponseMetricsFromJSON(json: any): ServiceMetricsRangeResponseMetrics {
    return ServiceMetricsRangeResponseMetricsFromJSONTyped(json, false);
}

export function ServiceMetricsRangeResponseMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceMetricsRangeResponseMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': json['timestamp'],
        'element': ServiceMetricsResponseFromJSON(json['element']),
    };
}

export function ServiceMetricsRangeResponseMetricsToJSON(value?: ServiceMetricsRangeResponseMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp,
        'element': ServiceMetricsResponseToJSON(value.element),
    };
}

