/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceReplicaStatus
 */
export interface ServiceReplicaStatus {
    /**
     * 
     * @type {string}
     * @memberof ServiceReplicaStatus
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceReplicaStatus
     */
    serviceId: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceReplicaStatus
     */
    serviceVersion: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceReplicaStatus
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceReplicaStatus
     */
    state: ServiceReplicaStatusStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ServiceReplicaStatus
     */
    message: string;
    /**
     * 
     * @type {Date}
     * @memberof ServiceReplicaStatus
     */
    timestamp: Date;
}

/**
* @export
* @enum {string}
*/
export enum ServiceReplicaStatusStateEnum {
    Running = 'RUNNING',
    Failed = 'FAILED',
    Notready = 'NOTREADY',
    Stopped = 'STOPPED',
    Pending = 'PENDING',
    Initializing = 'INITIALIZING',
    Starting = 'STARTING',
    Stopping = 'STOPPING',
    Unknown = 'UNKNOWN'
}

export function ServiceReplicaStatusFromJSON(json: any): ServiceReplicaStatus {
    return ServiceReplicaStatusFromJSONTyped(json, false);
}

export function ServiceReplicaStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceReplicaStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'serviceId': json['serviceId'],
        'serviceVersion': json['serviceVersion'],
        'tenantId': json['tenantId'],
        'state': json['state'],
        'message': json['message'],
        'timestamp': (new Date(json['timestamp'])),
    };
}

export function ServiceReplicaStatusToJSON(value?: ServiceReplicaStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'serviceId': value.serviceId,
        'serviceVersion': value.serviceVersion,
        'tenantId': value.tenantId,
        'state': value.state,
        'message': value.message,
        'timestamp': (value.timestamp.toISOString()),
    };
}

