/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagServiceResponse
 */
export interface TagServiceResponse {
    /**
     * 
     * @type {string}
     * @memberof TagServiceResponse
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof TagServiceResponse
     */
    tagId: string;
    /**
     * 
     * @type {string}
     * @memberof TagServiceResponse
     */
    serviceId: string;
    /**
     * 
     * @type {Date}
     * @memberof TagServiceResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof TagServiceResponse
     */
    updatedAt: Date;
}

export function TagServiceResponseFromJSON(json: any): TagServiceResponse {
    return TagServiceResponseFromJSONTyped(json, false);
}

export function TagServiceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagServiceResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantId': json['tenantId'],
        'tagId': json['tagId'],
        'serviceId': json['serviceId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function TagServiceResponseToJSON(value?: TagServiceResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantId': value.tenantId,
        'tagId': value.tagId,
        'serviceId': value.serviceId,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

