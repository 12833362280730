/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceLogsRangeResponseLogs
 */
export interface ServiceLogsRangeResponseLogs {
    /**
     * Unix timestamp
     * @type {number}
     * @memberof ServiceLogsRangeResponseLogs
     */
    timestamp: number;
    /**
     * The log message
     * @type {string}
     * @memberof ServiceLogsRangeResponseLogs
     */
    message: string;
}

export function ServiceLogsRangeResponseLogsFromJSON(json: any): ServiceLogsRangeResponseLogs {
    return ServiceLogsRangeResponseLogsFromJSONTyped(json, false);
}

export function ServiceLogsRangeResponseLogsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceLogsRangeResponseLogs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': json['timestamp'],
        'message': json['message'],
    };
}

export function ServiceLogsRangeResponseLogsToJSON(value?: ServiceLogsRangeResponseLogs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp,
        'message': value.message,
    };
}

