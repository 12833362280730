/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceProxy
 */
export interface ServiceProxy {
    /**
     * 
     * @type {string}
     * @memberof ServiceProxy
     */
    logLevel: ServiceProxyLogLevelEnum;
    /**
     * 
     * @type {number}
     * @memberof ServiceProxy
     */
    cpuMinInMilliseconds: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceProxy
     */
    cpuMaxInMilliseconds: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceProxy
     */
    memoryMinInMegaBytes: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceProxy
     */
    memoryMaxInMegaBytes: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceProxy
     */
    routeTimeoutInSeconds?: number;
}

/**
* @export
* @enum {string}
*/
export enum ServiceProxyLogLevelEnum {
    Trace = 'TRACE',
    Debug = 'DEBUG',
    Info = 'INFO',
    Warn = 'WARN',
    Error = 'ERROR',
    Critical = 'CRITICAL'
}

export function ServiceProxyFromJSON(json: any): ServiceProxy {
    return ServiceProxyFromJSONTyped(json, false);
}

export function ServiceProxyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceProxy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'logLevel': json['logLevel'],
        'cpuMinInMilliseconds': json['cpuMinInMilliseconds'],
        'cpuMaxInMilliseconds': json['cpuMaxInMilliseconds'],
        'memoryMinInMegaBytes': json['memoryMinInMegaBytes'],
        'memoryMaxInMegaBytes': json['memoryMaxInMegaBytes'],
        'routeTimeoutInSeconds': !exists(json, 'routeTimeoutInSeconds') ? undefined : json['routeTimeoutInSeconds'],
    };
}

export function ServiceProxyToJSON(value?: ServiceProxy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'logLevel': value.logLevel,
        'cpuMinInMilliseconds': value.cpuMinInMilliseconds,
        'cpuMaxInMilliseconds': value.cpuMaxInMilliseconds,
        'memoryMinInMegaBytes': value.memoryMinInMegaBytes,
        'memoryMaxInMegaBytes': value.memoryMaxInMegaBytes,
        'routeTimeoutInSeconds': value.routeTimeoutInSeconds,
    };
}

