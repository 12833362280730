/* eslint-disable import/prefer-default-export, react/prop-types */
import React from "react";
import Layout from "./src/components/layout/Layout";
import ThemeTypeProvider from "./src/components/provider/ThemeTypeProvider";
import {StyledEngineProvider} from "@mui/material/styles";
import UiConfigProvider from "./src/components/provider/UiConfigProvider";
import KeycloakProvider from "./src/components/provider/KeycloakProvider";
import LoginProvider from "./src/components/provider/LoginProvider";
import OidcTokenProvider from "./src/components/provider/OidcTokenProvider";
import TenantProvider from "./src/components/provider/TenantProvider";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

export const wrapRootElement = ({element}) => {
    return <StyledEngineProvider injectFirst>
        <ThemeTypeProvider>
            <UiConfigProvider>
                <LoginProvider>
                    <OidcTokenProvider>
                        <KeycloakProvider>
                            <TenantProvider>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Layout>{element}</Layout>
                                </LocalizationProvider>
                            </TenantProvider>
                        </KeycloakProvider>
                    </OidcTokenProvider>
                </LoginProvider>
            </UiConfigProvider>
        </ThemeTypeProvider>
    </StyledEngineProvider>;
};
