/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceMount
 */
export interface ServiceMount {
    /**
     * 
     * @type {string}
     * @memberof ServiceMount
     */
    mountPath: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceMount
     */
    volumeName: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceMount
     */
    volumeSubPath?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceMount
     */
    readOnly: boolean;
}

export function ServiceMountFromJSON(json: any): ServiceMount {
    return ServiceMountFromJSONTyped(json, false);
}

export function ServiceMountFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceMount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mountPath': json['mountPath'],
        'volumeName': json['volumeName'],
        'volumeSubPath': !exists(json, 'volumeSubPath') ? undefined : json['volumeSubPath'],
        'readOnly': json['readOnly'],
    };
}

export function ServiceMountToJSON(value?: ServiceMount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mountPath': value.mountPath,
        'volumeName': value.volumeName,
        'volumeSubPath': value.volumeSubPath,
        'readOnly': value.readOnly,
    };
}

