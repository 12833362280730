/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TagMetricsRangeResponseMetrics,
    TagMetricsRangeResponseMetricsFromJSON,
    TagMetricsRangeResponseMetricsFromJSONTyped,
    TagMetricsRangeResponseMetricsToJSON,
} from './TagMetricsRangeResponseMetrics';

/**
 * 
 * @export
 * @interface TagMetricsRangeResponse
 */
export interface TagMetricsRangeResponse {
    /**
     * 
     * @type {Array<TagMetricsRangeResponseMetrics>}
     * @memberof TagMetricsRangeResponse
     */
    metrics: Array<TagMetricsRangeResponseMetrics>;
}

export function TagMetricsRangeResponseFromJSON(json: any): TagMetricsRangeResponse {
    return TagMetricsRangeResponseFromJSONTyped(json, false);
}

export function TagMetricsRangeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagMetricsRangeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metrics': ((json['metrics'] as Array<any>).map(TagMetricsRangeResponseMetricsFromJSON)),
    };
}

export function TagMetricsRangeResponseToJSON(value?: TagMetricsRangeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metrics': ((value.metrics as Array<any>).map(TagMetricsRangeResponseMetricsToJSON)),
    };
}

