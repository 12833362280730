import {Adapter, Page, Pageable, UUID} from "./interfaces";
import {LoginHolder} from "../components/provider/LoginProvider";
import {apiDelete, apiGet, apiPost, apiPut} from "../misc/api";
import {PageFromJSON} from "../generated/models/Page";
import {CreateTenantSettingRequest} from "../generated/models/CreateTenantSettingRequest";
import {TenantSettingResponse, TenantSettingResponseFromJSON} from "../generated/models/TenantSettingResponse";
import {UpdateTenantSettingRequest} from "../generated/models/UpdateTenantSettingRequest";
import {
    TenantSettingDefinitionResponse,
    TenantSettingDefinitionResponseFromJSON
} from "../generated/models/TenantSettingDefinitionResponse";
import {SettingValueResponseFromJSON} from "../generated/models/SettingValueResponse";

export class TenantSettingAdapter implements Adapter<TenantSettingId, TenantSettingResponse> {
    private readonly base: string = `/tenant-settings`

    create(login: LoginHolder | null, elements: any[]): Promise<TenantSettingResponse[]> {
        return Promise.reject("Not implemented")
    }

    createOne(login: LoginHolder | null, element: CreateTenantSettingRequest): Promise<TenantSettingResponse> {
        return apiPost(login, this.base, element)
                .then(response => {
                    console.debug("Successfully created element", response)
                    return TenantSettingResponseFromJSON(response)
                })
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<TenantSettingResponse>> {
        return apiGet(login, `${this.base}?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<TenantSettingResponse>
                })
    }

    findDefinitions(login: LoginHolder | null, request: Pageable): Promise<Page<TenantSettingDefinitionResponse>> {
        return apiGet(login, `${this.base}/definitions?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    const mappedPage = PageFromJSON(response)
                    mappedPage.elements = response.elements.map((e: any) => TenantSettingDefinitionResponseFromJSON(e))
                    return mappedPage as Page<TenantSettingDefinitionResponse>
                })
    }

    get(login: LoginHolder | null, id: TenantSettingId): Promise<TenantSettingResponse | null> {
        return apiGet(login, `${this.base}/${id.name}/${id.tenantId}`)
                .then(response => {
                    console.debug("Successfully retrieved element", response)
                    return TenantSettingResponseFromJSON(response)
                })
    }

    getValue(login: LoginHolder | null, id: TenantSettingId): Promise<string | null> {
        return apiGet(login, `${this.base}/${id.name}/${id.tenantId}/value`)
                .then(response => {
                    console.debug("Successfully retrieved element", response)
                    return SettingValueResponseFromJSON(response).value || null
                })
    }

    update(login: LoginHolder | null, elements: any[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOne(login: LoginHolder | null, element: UpdateTenantSettingRequest): Promise<void> {
        return apiPut(login, `${this.base}/${element.name}/${element.tenantId}`, element)
                .then(() => console.debug("Successfully updated element"))
    }

    delete(login: LoginHolder | null, ids: TenantSettingId[]): Promise<void> {
        return apiDelete(login, `${this.base}/batch`, {
            elements: ids.map(id => Object.assign({}, {
                tenantId: id.tenantId,
                name: id.name,
            }))
        })
                .then(response => {
                    console.debug("Successfully deleted elements", response)
                })
    }

    deleteOne(login: LoginHolder | null, id: TenantSettingId): Promise<void> {
        return apiDelete(login, `${this.base}/${id.name}/${id.tenantId}`)
                .then(() => {
                    console.debug("Successfully deleted element", id)
                })
    }

}

export interface TenantSettingId {
    tenantId: UUID
    name: string
}

export enum TenantSettingDefinition {
    CURRENCY,
    SMTP_ENABLED,
    SMTP_FROM_ADDRESS,
    SMTP_FROM_NAME,
    SMTP_REPLY_ADDRESS,
    SMTP_HOST,
    SMTP_PORT,
    SMTP_USER,
    SMTP_PASSWORD,
    SMTP_AUTH_ENABLED,
    SMTP_STARTTLS_ENABLED,
    SMTP_SSL_PROTOCOLS,
    SMTP_SSL_TRUSTED_HOSTS,
    SMTP_TIMEOUT,
    SMTP_CONNECTION_TIMEOUT,
    CONTAINER_OPS_TENANT_CPU_MAX,
    CONTAINER_OPS_TENANT_MEMORY_MAX,
    CONTAINER_OPS_TENANT_STORAGE_MAX,
    CONTAINER_OPS_TENANT_STORAGE_CLASS_DEFAULT,
    CONTAINER_OPS_TENANT_AVAILABLE_STORAGE_CLASSES,
    CONTAINER_OPS_TENANT_REQUEST_MAX_BODY_SIZE,
    CONTAINER_OPS_SERVICE_CPU_MAX,
    CONTAINER_OPS_SERVICE_MEMORY_MAX,
    CONTAINER_OPS_SERVICE_STORAGE_MAX,
    AGENT_CPU_MIN,
    AGENT_CPU_MAX,
    AGENT_MEMORY_MIN,
    AGENT_MEMORY_MAX,
    AGENT_RUN_SELECTORS,
    AGENT_RUN_RESTRICTIONS,
    SERVICE_ENFORCE_RUN_SELECTORS,
    SERVICE_AVAILABLE_RUN_SELECTORS,
    SERVICE_ENFORCE_RUN_RESTRICTIONS,
    SERVICE_AVAILABLE_RUN_RESTRICTIONS,
}
