/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TagServiceResponse,
    TagServiceResponseFromJSON,
    TagServiceResponseFromJSONTyped,
    TagServiceResponseToJSON,
} from './TagServiceResponse';

/**
 * 
 * @export
 * @interface TagServicesResponse
 */
export interface TagServicesResponse {
    /**
     * 
     * @type {Array<TagServiceResponse>}
     * @memberof TagServicesResponse
     */
    elements: Array<TagServiceResponse>;
}

export function TagServicesResponseFromJSON(json: any): TagServicesResponse {
    return TagServicesResponseFromJSONTyped(json, false);
}

export function TagServicesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagServicesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'elements': ((json['elements'] as Array<any>).map(TagServiceResponseFromJSON)),
    };
}

export function TagServicesResponseToJSON(value?: TagServicesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'elements': ((value.elements as Array<any>).map(TagServiceResponseToJSON)),
    };
}

