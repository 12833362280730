/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceInitImage
 */
export interface ServiceInitImage {
    /**
     * 
     * @type {string}
     * @memberof ServiceInitImage
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceInitImage
     */
    tag?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceInitImage
     */
    command: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceInitImage
     */
    isPrivileged?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ServiceInitImage
     */
    user?: number;
}

export function ServiceInitImageFromJSON(json: any): ServiceInitImage {
    return ServiceInitImageFromJSONTyped(json, false);
}

export function ServiceInitImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceInitImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'tag': !exists(json, 'tag') ? undefined : json['tag'],
        'command': json['command'],
        'isPrivileged': !exists(json, 'isPrivileged') ? undefined : json['isPrivileged'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function ServiceInitImageToJSON(value?: ServiceInitImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'tag': value.tag,
        'command': value.command,
        'isPrivileged': value.isPrivileged,
        'user': value.user,
    };
}

