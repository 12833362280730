import {apiGet} from "../misc/api";
import {LoginHolder} from "../components/provider/LoginProvider";
import {
    TenantMetricsRangeResponse,
    TenantMetricsRangeResponseFromJSON
} from "../generated/models/TenantMetricsRangeResponse";
import moment from "moment";
import {UserMetricsRangeResponse, UserMetricsRangeResponseFromJSON} from "../generated/models/UserMetricsRangeResponse";
import {
    ApiKeyMetricsRangeResponse,
    ApiKeyMetricsRangeResponseFromJSON
} from "../generated/models/ApiKeyMetricsRangeResponse";
import {
    ServiceMetricsRangeResponse,
    ServiceMetricsRangeResponseFromJSON
} from "../generated/models/ServiceMetricsRangeResponse";
import {
    ResourceMetricsRangeResponse,
    ResourceMetricsRangeResponseFromJSON
} from "../generated/models/ResourceMetricsRangeResponse";
import {ResourceMetricsResponse, ResourceMetricsResponseFromJSON} from "../generated/models/ResourceMetricsResponse";
import {TagMetricsRangeResponse, TagMetricsRangeResponseFromJSON} from "../generated/models/TagMetricsRangeResponse";

export class MetricsAdapter {
    private readonly base: string = `/metrics`

    resourcesAt(login: LoginHolder | null, timestamp: Date, tenantId: string | null = null): Promise<ResourceMetricsResponse> {
        const formattedTimestamp = moment(timestamp).toISOString()
        return apiGet(login, `${this.base}/resources?timestamp=${formattedTimestamp}${tenantId ? "&tenantId=" + tenantId : ""}`)
                .then(response => {
                    console.debug("Successfully retrieved metrics", response)
                    return ResourceMetricsResponseFromJSON(response)
                })
    }

    resources(login: LoginHolder | null, start: Date, end: Date, intervalInSeconds: number, tenantId: string | null = null): Promise<ResourceMetricsRangeResponse> {
        const formattedStart = moment(start).toISOString()
        const formattedEnd = moment(end).toISOString()
        const formattedInterval = `PT${intervalInSeconds}S`
        return apiGet(login, `${this.base}/resources/range?start=${formattedStart}&end=${formattedEnd}&interval=${formattedInterval}${tenantId ? "&tenantId=" + tenantId : ""}`)
                .then(response => {
                    console.debug("Successfully retrieved metrics", response)
                    return ResourceMetricsRangeResponseFromJSON(response)
                })
    }

    tenants(login: LoginHolder | null, start: Date, end: Date, intervalInSeconds: number): Promise<TenantMetricsRangeResponse> {
        const formattedStart = moment(start).toISOString()
        const formattedEnd = moment(end).toISOString()
        const formattedInterval = `PT${intervalInSeconds}S`
        return apiGet(login, `${this.base}/tenants/range?start=${formattedStart}&end=${formattedEnd}&interval=${formattedInterval}`)
                .then(response => {
                    console.debug("Successfully retrieved metrics", response)
                    return TenantMetricsRangeResponseFromJSON(response)
                })
    }

    users(login: LoginHolder | null, start: Date, end: Date, intervalInSeconds: number): Promise<UserMetricsRangeResponse> {
        const formattedStart = moment(start).toISOString()
        const formattedEnd = moment(end).toISOString()
        const formattedInterval = `PT${intervalInSeconds}S`
        return apiGet(login, `${this.base}/users/range?start=${formattedStart}&end=${formattedEnd}&interval=${formattedInterval}`)
                .then(response => {
                    console.debug("Successfully retrieved metrics", response)
                    return UserMetricsRangeResponseFromJSON(response)
                })
    }

    apiKeys(login: LoginHolder | null, start: Date, end: Date, intervalInSeconds: number): Promise<ApiKeyMetricsRangeResponse> {
        const formattedStart = moment(start).toISOString()
        const formattedEnd = moment(end).toISOString()
        const formattedInterval = `PT${intervalInSeconds}S`
        return apiGet(login, `${this.base}/api-keys/range?start=${formattedStart}&end=${formattedEnd}&interval=${formattedInterval}`)
                .then(response => {
                    console.debug("Successfully retrieved metrics", response)
                    return ApiKeyMetricsRangeResponseFromJSON(response)
                })
    }

    services(login: LoginHolder | null, start: Date, end: Date, intervalInSeconds: number, tenantId: string | null = null): Promise<ServiceMetricsRangeResponse> {
        const formattedStart = moment(start).toISOString()
        const formattedEnd = moment(end).toISOString()
        const formattedInterval = `PT${intervalInSeconds}S`
        return apiGet(login, `${this.base}/services/range?start=${formattedStart}&end=${formattedEnd}&interval=${formattedInterval}${tenantId ? "&tenantId=" + tenantId : ""}`)
                .then(response => {
                    console.debug("Successfully retrieved metrics", response)
                    return ServiceMetricsRangeResponseFromJSON(response)
                })
    }

    tags(login: LoginHolder | null, start: Date, end: Date, intervalInSeconds: number, tenantId: string | null = null): Promise<TagMetricsRangeResponse> {
        const formattedStart = moment(start).toISOString()
        const formattedEnd = moment(end).toISOString()
        const formattedInterval = `PT${intervalInSeconds}S`
        return apiGet(login, `${this.base}/tags/range?start=${formattedStart}&end=${formattedEnd}&interval=${formattedInterval}${tenantId ? "&tenantId=" + tenantId : ""}`)
                .then(response => {
                    console.debug("Successfully retrieved metrics", response)
                    return TagMetricsRangeResponseFromJSON(response)
                })
    }

}
