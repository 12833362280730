import {Page, Pageable, UUID} from "./interfaces";
import {UserResponse, UserResponseFromJSON} from "../generated/models/UserResponse";
import {LoginHolder} from "../components/provider/LoginProvider";
import {apiDelete, apiGet, apiPost, apiPut} from "../misc/api";
import {PageFromJSON} from "../generated/models/Page";
import {UpdateUserRequest} from "../generated/models/UpdateUserRequest";
import {AbstractAdapter} from "./AbstractAdapter";
import {InviteUserRequest} from "../generated/models/InviteUserRequest";
import {CreateOneUserRequest} from "../generated/models/CreateOneUserRequest";
import {UserRoleResponse} from "../generated/models/UserRoleResponse";
import {CreateTenantUsersRequest} from "../generated/models/CreateTenantUsersRequest";
import {TenantUsersResponse, TenantUsersResponseFromJSON} from "../generated/models/TenantUsersResponse";
import {DeleteTenantUsersRequest} from "../generated/models/DeleteTenantUsersRequest";
import {CreateUserRolesRequest} from "../generated/models/CreateUserRolesRequest";
import {DeleteUserRolesRequest} from "../generated/models/DeleteUserRolesRequest";
import {UserRolesResponse, UserRolesResponseFromJSON} from "../generated/models/UserRolesResponse";
import {TenantUserResponse} from "../generated/models/TenantUserResponse";
import {UserNameResponse, UserNameResponseFromJSON} from "../generated/models/UserNameResponse";

export class UserAdapter extends AbstractAdapter<UUID, UserResponse> {
    private readonly base: string = `/users`
    private readonly baseTenantConnections: string = `/tenant-user-connections`
    private readonly baseRoleConnections: string = `/user-role-connections`

    protected basePath(): string {
        return this.base;
    }

    invite(login: LoginHolder | null, element: InviteUserRequest): Promise<UserResponse> {
        return apiPost(login, `${this.base}/invite`, element)
                .then(response => {
                    console.debug("Successfully invited user", response)
                    return UserResponseFromJSON(response)
                })
    }

    create(login: LoginHolder | null, elements: any[]): Promise<UserResponse[]> {
        return Promise.reject("Not implemented")
    }

    createOne(login: LoginHolder | null, element: CreateOneUserRequest): Promise<UserResponse> {
        return apiPost(login, this.base, element)
                .then(response => {
                    console.debug("Successfully created element", response)
                    return UserResponseFromJSON(response)
                })
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<UserResponse>> {
        return apiGet(login, `${this.base}?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<UserResponse>
                })
    }

    get(login: LoginHolder | null, id: UUID): Promise<UserResponse | null> {
        return apiGet(login, `${this.base}/${id}`)
                .then(response => {
                    console.debug("Successfully retrieved element", response)
                    return UserResponseFromJSON(response)
                })
    }

    getName(login: LoginHolder | null, id: UUID): Promise<UserNameResponse | null> {
        return apiGet(login, `${this.base}/${id}/name`)
                .then(response => {
                    console.debug("Successfully retrieved element", response)
                    return UserNameResponseFromJSON(response)
                })
    }

    update(login: LoginHolder | null, elements: UpdateUserRequest[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOne(login: LoginHolder | null, element: UpdateUserRequest): Promise<void> {
        return apiPut(login, `${this.base}/${element.id}`, element)
                .then(() => console.debug("Successfully updated element"))
    }

    //<editor-fold desc="Tenants">
    addTenants(login: LoginHolder | null, request: CreateTenantUsersRequest): Promise<TenantUsersResponse> {
        return apiPost(login, `${this.baseTenantConnections}/batch`, request)
                .then(response => {
                    console.debug("Successfully created elements", response)
                    return TenantUsersResponseFromJSON(response)
                })
    }

    removeTenants(login: LoginHolder | null, request: DeleteTenantUsersRequest): Promise<void> {
        return apiDelete(login, `${this.baseTenantConnections}/batch`, request)
                .then(() => {
                    console.debug("Successfully deleted elements", request)
                })
    }

    findTenants(login: LoginHolder | null, request: Pageable): Promise<Page<TenantUserResponse>> {
        return apiGet(login, `${this.baseTenantConnections}?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<TenantUserResponse>
                })
    }
    //</editor-fold>

    //<editor-fold desc="Roles">
    addRoles(login: LoginHolder | null, request: CreateUserRolesRequest): Promise<UserRolesResponse> {
        return apiPost(login, `${this.baseRoleConnections}/batch`, request)
                .then(response => {
                    console.debug("Successfully created elements", response)
                    return UserRolesResponseFromJSON(response)
                })
    }

    removeRoles(login: LoginHolder | null, request: DeleteUserRolesRequest): Promise<void> {
        return apiDelete(login, `${this.baseRoleConnections}/batch`, request)
                .then(() => {
                    console.debug("Successfully deleted elements", request)
                })
    }

    findRoles(login: LoginHolder | null, request: Pageable): Promise<Page<UserRoleResponse>> {
        return apiGet(login, `${this.baseRoleConnections}?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<UserRoleResponse>
                })
    }

    //</editor-fold>

}
