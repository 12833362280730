/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ServiceEndpoint,
    ServiceEndpointFromJSON,
    ServiceEndpointFromJSONTyped,
    ServiceEndpointToJSON,
} from './ServiceEndpoint';
import {
    ServiceEnvironment,
    ServiceEnvironmentFromJSON,
    ServiceEnvironmentFromJSONTyped,
    ServiceEnvironmentToJSON,
} from './ServiceEnvironment';
import {
    ServiceImage,
    ServiceImageFromJSON,
    ServiceImageFromJSONTyped,
    ServiceImageToJSON,
} from './ServiceImage';
import {
    ServiceProxy,
    ServiceProxyFromJSON,
    ServiceProxyFromJSONTyped,
    ServiceProxyToJSON,
} from './ServiceProxy';
import {
    ServiceReachability,
    ServiceReachabilityFromJSON,
    ServiceReachabilityFromJSONTyped,
    ServiceReachabilityToJSON,
} from './ServiceReachability';
import {
    ServiceResources,
    ServiceResourcesFromJSON,
    ServiceResourcesFromJSONTyped,
    ServiceResourcesToJSON,
} from './ServiceResources';
import {
    ServiceTelemetry,
    ServiceTelemetryFromJSON,
    ServiceTelemetryFromJSONTyped,
    ServiceTelemetryToJSON,
} from './ServiceTelemetry';

/**
 * 
 * @export
 * @interface ServiceSpecification
 */
export interface ServiceSpecification {
    /**
     * 
     * @type {ServiceImage}
     * @memberof ServiceSpecification
     */
    image: ServiceImage;
    /**
     * 
     * @type {ServiceResources}
     * @memberof ServiceSpecification
     */
    resources: ServiceResources;
    /**
     * 
     * @type {ServiceEnvironment}
     * @memberof ServiceSpecification
     */
    environment: ServiceEnvironment;
    /**
     * Definition of endpoints.
     * @type {Array<ServiceEndpoint>}
     * @memberof ServiceSpecification
     */
    endpoints: Array<ServiceEndpoint>;
    /**
     * 
     * @type {ServiceTelemetry}
     * @memberof ServiceSpecification
     */
    telemetry: ServiceTelemetry;
    /**
     * 
     * @type {ServiceReachability}
     * @memberof ServiceSpecification
     */
    reachability: ServiceReachability;
    /**
     * 
     * @type {ServiceProxy}
     * @memberof ServiceSpecification
     */
    proxy?: ServiceProxy;
}

export function ServiceSpecificationFromJSON(json: any): ServiceSpecification {
    return ServiceSpecificationFromJSONTyped(json, false);
}

export function ServiceSpecificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceSpecification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'image': ServiceImageFromJSON(json['image']),
        'resources': ServiceResourcesFromJSON(json['resources']),
        'environment': ServiceEnvironmentFromJSON(json['environment']),
        'endpoints': ((json['endpoints'] as Array<any>).map(ServiceEndpointFromJSON)),
        'telemetry': ServiceTelemetryFromJSON(json['telemetry']),
        'reachability': ServiceReachabilityFromJSON(json['reachability']),
        'proxy': !exists(json, 'proxy') ? undefined : ServiceProxyFromJSON(json['proxy']),
    };
}

export function ServiceSpecificationToJSON(value?: ServiceSpecification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image': ServiceImageToJSON(value.image),
        'resources': ServiceResourcesToJSON(value.resources),
        'environment': ServiceEnvironmentToJSON(value.environment),
        'endpoints': ((value.endpoints as Array<any>).map(ServiceEndpointToJSON)),
        'telemetry': ServiceTelemetryToJSON(value.telemetry),
        'reachability': ServiceReachabilityToJSON(value.reachability),
        'proxy': ServiceProxyToJSON(value.proxy),
    };
}

