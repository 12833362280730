/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ServiceLabel,
    ServiceLabelFromJSON,
    ServiceLabelFromJSONTyped,
    ServiceLabelToJSON,
} from './ServiceLabel';
import {
    ServiceRestriction,
    ServiceRestrictionFromJSON,
    ServiceRestrictionFromJSONTyped,
    ServiceRestrictionToJSON,
} from './ServiceRestriction';

/**
 * 
 * @export
 * @interface ServiceResources
 */
export interface ServiceResources {
    /**
     * 
     * @type {boolean}
     * @memberof ServiceResources
     */
    isOnDemand?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ServiceResources
     */
    coolDownPeriodInSeconds?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceResources
     */
    replicas: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceResources
     */
    cpuMinInMilliseconds: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceResources
     */
    cpuMaxInMilliseconds: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceResources
     */
    memoryMinInMegaBytes: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceResources
     */
    memoryMaxInMegaBytes: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceResources
     */
    storageInMegaBytes: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceResources
     */
    storageClass?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceResources
     */
    useStorageForEachReplica?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ServiceResources
     */
    gpu: number;
    /**
     * 
     * @type {Array<ServiceLabel>}
     * @memberof ServiceResources
     */
    runSelectors?: Array<ServiceLabel>;
    /**
     * 
     * @type {Array<ServiceRestriction>}
     * @memberof ServiceResources
     */
    runRestrictions?: Array<ServiceRestriction>;
}

export function ServiceResourcesFromJSON(json: any): ServiceResources {
    return ServiceResourcesFromJSONTyped(json, false);
}

export function ServiceResourcesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceResources {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isOnDemand': !exists(json, 'isOnDemand') ? undefined : json['isOnDemand'],
        'coolDownPeriodInSeconds': !exists(json, 'coolDownPeriodInSeconds') ? undefined : json['coolDownPeriodInSeconds'],
        'replicas': json['replicas'],
        'cpuMinInMilliseconds': json['cpuMinInMilliseconds'],
        'cpuMaxInMilliseconds': json['cpuMaxInMilliseconds'],
        'memoryMinInMegaBytes': json['memoryMinInMegaBytes'],
        'memoryMaxInMegaBytes': json['memoryMaxInMegaBytes'],
        'storageInMegaBytes': json['storageInMegaBytes'],
        'storageClass': !exists(json, 'storageClass') ? undefined : json['storageClass'],
        'useStorageForEachReplica': !exists(json, 'useStorageForEachReplica') ? undefined : json['useStorageForEachReplica'],
        'gpu': json['gpu'],
        'runSelectors': !exists(json, 'runSelectors') ? undefined : ((json['runSelectors'] as Array<any>).map(ServiceLabelFromJSON)),
        'runRestrictions': !exists(json, 'runRestrictions') ? undefined : ((json['runRestrictions'] as Array<any>).map(ServiceRestrictionFromJSON)),
    };
}

export function ServiceResourcesToJSON(value?: ServiceResources | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isOnDemand': value.isOnDemand,
        'coolDownPeriodInSeconds': value.coolDownPeriodInSeconds,
        'replicas': value.replicas,
        'cpuMinInMilliseconds': value.cpuMinInMilliseconds,
        'cpuMaxInMilliseconds': value.cpuMaxInMilliseconds,
        'memoryMinInMegaBytes': value.memoryMinInMegaBytes,
        'memoryMaxInMegaBytes': value.memoryMaxInMegaBytes,
        'storageInMegaBytes': value.storageInMegaBytes,
        'storageClass': value.storageClass,
        'useStorageForEachReplica': value.useStorageForEachReplica,
        'gpu': value.gpu,
        'runSelectors': value.runSelectors === undefined ? undefined : ((value.runSelectors as Array<any>).map(ServiceLabelToJSON)),
        'runRestrictions': value.runRestrictions === undefined ? undefined : ((value.runRestrictions as Array<any>).map(ServiceRestrictionToJSON)),
    };
}

