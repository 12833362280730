/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ApiKeyPermissionResponse,
    ApiKeyPermissionResponseFromJSON,
    ApiKeyPermissionResponseFromJSONTyped,
    ApiKeyPermissionResponseToJSON,
} from './ApiKeyPermissionResponse';

/**
 * 
 * @export
 * @interface ApiKeyPermissionsResponse
 */
export interface ApiKeyPermissionsResponse {
    /**
     * 
     * @type {Array<ApiKeyPermissionResponse>}
     * @memberof ApiKeyPermissionsResponse
     */
    elements: Array<ApiKeyPermissionResponse>;
}

export function ApiKeyPermissionsResponseFromJSON(json: any): ApiKeyPermissionsResponse {
    return ApiKeyPermissionsResponseFromJSONTyped(json, false);
}

export function ApiKeyPermissionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiKeyPermissionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'elements': ((json['elements'] as Array<any>).map(ApiKeyPermissionResponseFromJSON)),
    };
}

export function ApiKeyPermissionsResponseToJSON(value?: ApiKeyPermissionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'elements': ((value.elements as Array<any>).map(ApiKeyPermissionResponseToJSON)),
    };
}

