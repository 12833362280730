import React, {useContext, useEffect} from "react";
import {useMediaQuery} from "@mui/material";
import {darkTheme, lightTheme} from "../../theme";
import CssBaseline from "@mui/material/CssBaseline";
import {ThemeProvider} from "@mui/material/styles";
import {PREFERRED_THEME_STORAGE_KEY, ThemeTypeContext} from "../provider/ThemeTypeProvider";

export interface Props {
    children: NonNullable<React.ReactNode>
}

export default function Layout(props: Props) {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
    const {themeType, setThemeType} = useContext(ThemeTypeContext)

    useEffect(() => {
        if (!themeType) {
            const preferredTheme = localStorage.getItem(PREFERRED_THEME_STORAGE_KEY)
            if (preferredTheme && ['light', 'dark'].includes(preferredTheme)) {
                setThemeType(preferredTheme as 'light' | 'dark')
            } else if (prefersDarkMode) {
                localStorage.setItem(PREFERRED_THEME_STORAGE_KEY, 'dark')
                setThemeType('dark')
            } else {
                localStorage.setItem(PREFERRED_THEME_STORAGE_KEY, 'light')
                setThemeType('light')
            }
        }
    })

    return (
            <>
                <ThemeProvider theme={(!themeType || themeType === 'light') ? lightTheme : darkTheme}>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline/>
                    {props.children}
                </ThemeProvider>
            </>
    )
}
