/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateCreditTransactionRequest
 */
export interface CreateCreditTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCreditTransactionRequest
     */
    tenantId: string;
    /**
     * 
     * @type {number}
     * @memberof CreateCreditTransactionRequest
     */
    credits: number;
    /**
     * 
     * @type {number}
     * @memberof CreateCreditTransactionRequest
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCreditTransactionRequest
     */
    type: CreateCreditTransactionRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateCreditTransactionRequest
     */
    description: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateCreditTransactionRequest
     */
    valuedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateCreditTransactionRequest
     */
    expiredAt: Date;
}

/**
* @export
* @enum {string}
*/
export enum CreateCreditTransactionRequestTypeEnum {
    Deposit = 'DEPOSIT',
    Refund = 'REFUND'
}

export function CreateCreditTransactionRequestFromJSON(json: any): CreateCreditTransactionRequest {
    return CreateCreditTransactionRequestFromJSONTyped(json, false);
}

export function CreateCreditTransactionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCreditTransactionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantId': json['tenantId'],
        'credits': json['credits'],
        'amount': json['amount'],
        'type': json['type'],
        'description': json['description'],
        'valuedAt': (new Date(json['valuedAt'])),
        'expiredAt': (new Date(json['expiredAt'])),
    };
}

export function CreateCreditTransactionRequestToJSON(value?: CreateCreditTransactionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantId': value.tenantId,
        'credits': value.credits,
        'amount': value.amount,
        'type': value.type,
        'description': value.description,
        'valuedAt': (value.valuedAt.toISOString()),
        'expiredAt': (value.expiredAt.toISOString()),
    };
}

