/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PermissionAction {
    Create = 'CREATE',
    Read = 'READ',
    Update = 'UPDATE',
    Delete = 'DELETE',
    Execute = 'EXECUTE',
    All = 'ALL'
}

export function PermissionActionFromJSON(json: any): PermissionAction {
    return PermissionActionFromJSONTyped(json, false);
}

export function PermissionActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionAction {
    return json as PermissionAction;
}

export function PermissionActionToJSON(value?: PermissionAction | null): any {
    return value as any;
}

