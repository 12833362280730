/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagResponse
 */
export interface TagResponse {
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof TagResponse
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof TagResponse
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TagResponse
     */
    updatedAt?: Date;
}

export function TagResponseFromJSON(json: any): TagResponse {
    return TagResponseFromJSONTyped(json, false);
}

export function TagResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tenantId': json['tenantId'],
        'name': json['name'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}

export function TagResponseToJSON(value?: TagResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'name': value.name,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}

