/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TagMetricsResponse,
    TagMetricsResponseFromJSON,
    TagMetricsResponseFromJSONTyped,
    TagMetricsResponseToJSON,
} from './TagMetricsResponse';

/**
 * 
 * @export
 * @interface TagMetricsRangeResponseMetrics
 */
export interface TagMetricsRangeResponseMetrics {
    /**
     * Unix timestamp
     * @type {number}
     * @memberof TagMetricsRangeResponseMetrics
     */
    timestamp: number;
    /**
     * 
     * @type {TagMetricsResponse}
     * @memberof TagMetricsRangeResponseMetrics
     */
    element: TagMetricsResponse;
}

export function TagMetricsRangeResponseMetricsFromJSON(json: any): TagMetricsRangeResponseMetrics {
    return TagMetricsRangeResponseMetricsFromJSONTyped(json, false);
}

export function TagMetricsRangeResponseMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagMetricsRangeResponseMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': json['timestamp'],
        'element': TagMetricsResponseFromJSON(json['element']),
    };
}

export function TagMetricsRangeResponseMetricsToJSON(value?: TagMetricsRangeResponseMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp,
        'element': TagMetricsResponseToJSON(value.element),
    };
}

