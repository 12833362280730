/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceEndpointType {
    Ordinary = 'ORDINARY',
    Api = 'API',
    Ui = 'UI',
    Health = 'HEALTH',
    Ready = 'READY',
    Started = 'STARTED',
    Metrics = 'METRICS',
    OpenApi = 'OPEN_API',
    Docs = 'DOCS'
}

export function ServiceEndpointTypeFromJSON(json: any): ServiceEndpointType {
    return ServiceEndpointTypeFromJSONTyped(json, false);
}

export function ServiceEndpointTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceEndpointType {
    return json as ServiceEndpointType;
}

export function ServiceEndpointTypeToJSON(value?: ServiceEndpointType | null): any {
    return value as any;
}

