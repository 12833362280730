/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ServiceReplicaStatus,
    ServiceReplicaStatusFromJSON,
    ServiceReplicaStatusFromJSONTyped,
    ServiceReplicaStatusToJSON,
} from './ServiceReplicaStatus';

/**
 * 
 * @export
 * @interface ServiceStatus
 */
export interface ServiceStatus {
    /**
     * 
     * @type {string}
     * @memberof ServiceStatus
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceStatus
     */
    serviceId: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceStatus
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceStatus
     */
    state: ServiceStatusStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ServiceStatus
     */
    message: string;
    /**
     * 
     * @type {Array<ServiceReplicaStatus>}
     * @memberof ServiceStatus
     */
    replicas: Array<ServiceReplicaStatus>;
    /**
     * 
     * @type {Date}
     * @memberof ServiceStatus
     */
    timestamp: Date;
}

/**
* @export
* @enum {string}
*/
export enum ServiceStatusStateEnum {
    Running = 'RUNNING',
    Failed = 'FAILED',
    Notready = 'NOTREADY',
    Stopped = 'STOPPED',
    Pending = 'PENDING',
    Initializing = 'INITIALIZING',
    Starting = 'STARTING',
    Stopping = 'STOPPING',
    Degraded = 'DEGRADED'
}

export function ServiceStatusFromJSON(json: any): ServiceStatus {
    return ServiceStatusFromJSONTyped(json, false);
}

export function ServiceStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'serviceId': json['serviceId'],
        'tenantId': json['tenantId'],
        'state': json['state'],
        'message': json['message'],
        'replicas': ((json['replicas'] as Array<any>).map(ServiceReplicaStatusFromJSON)),
        'timestamp': (new Date(json['timestamp'])),
    };
}

export function ServiceStatusToJSON(value?: ServiceStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'serviceId': value.serviceId,
        'tenantId': value.tenantId,
        'state': value.state,
        'message': value.message,
        'replicas': ((value.replicas as Array<any>).map(ServiceReplicaStatusToJSON)),
        'timestamp': (value.timestamp.toISOString()),
    };
}

