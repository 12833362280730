/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceVariable
 */
export interface ServiceVariable {
    /**
     * 
     * @type {string}
     * @memberof ServiceVariable
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceVariable
     */
    value: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceVariable
     */
    encrypted: boolean;
}

export function ServiceVariableFromJSON(json: any): ServiceVariable {
    return ServiceVariableFromJSONTyped(json, false);
}

export function ServiceVariableFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceVariable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'value': json['value'],
        'encrypted': json['encrypted'],
    };
}

export function ServiceVariableToJSON(value?: ServiceVariable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'value': value.value,
        'encrypted': value.encrypted,
    };
}

