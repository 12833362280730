/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ServiceEndpointProtocol {
    Tcp = 'TCP',
    Http = 'HTTP'
}

export function ServiceEndpointProtocolFromJSON(json: any): ServiceEndpointProtocol {
    return ServiceEndpointProtocolFromJSONTyped(json, false);
}

export function ServiceEndpointProtocolFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceEndpointProtocol {
    return json as ServiceEndpointProtocol;
}

export function ServiceEndpointProtocolToJSON(value?: ServiceEndpointProtocol | null): any {
    return value as any;
}

