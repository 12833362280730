/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateCreditTransactionRequest,
    CreateCreditTransactionRequestFromJSON,
    CreateCreditTransactionRequestFromJSONTyped,
    CreateCreditTransactionRequestToJSON,
} from './CreateCreditTransactionRequest';

/**
 * 
 * @export
 * @interface CreateCreditTransactionsRequest
 */
export interface CreateCreditTransactionsRequest {
    /**
     * 
     * @type {Array<CreateCreditTransactionRequest>}
     * @memberof CreateCreditTransactionsRequest
     */
    elements: Array<CreateCreditTransactionRequest>;
}

export function CreateCreditTransactionsRequestFromJSON(json: any): CreateCreditTransactionsRequest {
    return CreateCreditTransactionsRequestFromJSONTyped(json, false);
}

export function CreateCreditTransactionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCreditTransactionsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'elements': ((json['elements'] as Array<any>).map(CreateCreditTransactionRequestFromJSON)),
    };
}

export function CreateCreditTransactionsRequestToJSON(value?: CreateCreditTransactionsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'elements': ((value.elements as Array<any>).map(CreateCreditTransactionRequestToJSON)),
    };
}

