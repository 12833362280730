import {Adapter, globalSettingAdapter, Page, Pageable} from "./interfaces";
import {LoginHolder} from "../components/provider/LoginProvider";
import {apiDelete, apiGet, apiPost, apiPut} from "../misc/api";
import {PageFromJSON} from "../generated/models/Page";
import {CreateSettingRequest} from "../generated/models/CreateSettingRequest";
import {SettingResponse, SettingResponseFromJSON} from "../generated/models/SettingResponse";
import {UpdateSettingRequest} from "../generated/models/UpdateSettingRequest";
import {
    SettingDefinitionResponse,
    SettingDefinitionResponseFromJSON
} from "../generated/models/SettingDefinitionResponse";
import {isBrowser} from "../misc/misc";
import {SettingValueResponseFromJSON} from "../generated/models/SettingValueResponse";

export class GlobalSettingAdapter implements Adapter<string, SettingResponse> {
    private readonly base: string = `/settings`

    create(login: LoginHolder | null, elements: any[]): Promise<SettingResponse[]> {
        return Promise.reject("Not implemented")
    }

    createOne(login: LoginHolder | null, element: CreateSettingRequest): Promise<SettingResponse> {
        return apiPost(login, this.base, element)
                .then(response => {
                    console.debug("Successfully created element", response)
                    return SettingResponseFromJSON(response)
                })
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<SettingResponse>> {
        return apiGet(login, `${this.base}?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<SettingResponse>
                })
    }

    findDefinitions(login: LoginHolder | null, request: Pageable): Promise<Page<SettingDefinitionResponse>> {
        return apiGet(login, `${this.base}/definitions?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    const mappedPage = PageFromJSON(response)
                    mappedPage.elements = response.elements.map((e: any) => SettingDefinitionResponseFromJSON(e))
                    return mappedPage as Page<SettingDefinitionResponse>
                })
    }

    get(login: LoginHolder | null, id: string): Promise<SettingResponse | null> {
        return apiGet(login, `${this.base}/${id}`)
                .then(response => {
                    console.debug("Successfully retrieved element", response)
                    return SettingResponseFromJSON(response)
                })
    }

    getValue(login: LoginHolder | null, id: string): Promise<string> {
        return apiGet(login, `${this.base}/${id}/value`)
                .then(response => {
                    console.debug("Successfully retrieved element", response)
                    return SettingValueResponseFromJSON(response).value!
                })
    }

    update(login: LoginHolder | null, elements: any[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOne(login: LoginHolder | null, element: UpdateSettingRequest): Promise<void> {
        return apiPut(login, `${this.base}/${element.name}`, element)
                .then(() => console.debug("Successfully updated element"))
    }

    delete(login: LoginHolder | null, ids: string[]): Promise<void> {
        return apiDelete(login, `${this.base}/batch`, {names: ids})
                .then(response => {
                    console.debug("Successfully deleted elements", response)
                })
    }

    deleteOne(login: LoginHolder | null, id: string): Promise<void> {
        return apiDelete(login, `${this.base}/${id}`)
                .then(() => {
                    console.debug("Successfully deleted element", id)
                })
    }

}

export enum GlobalSettingDefinition {
    PASSWORD_POLICY,
    PASSWORD_HELP,
    PASSWORD_RESET_TOKEN_TTL,
    JWT_TOKEN_TTL,
    NOTIFICATION_RETENTION_INTERVAL,
    NOTIFICATION_SEEN_TTL,
    NOTIFICATION_UNSEEN_TTL,
    DNS_RECORD_VALUE_FOR_CUSTOM_DOMAIN,
}

export const getSetting: (definition: GlobalSettingDefinition) => string = (definition: GlobalSettingDefinition) => isBrowser && window.localStorage.getItem(`aiosSetting${GlobalSettingDefinition[definition]}`) ? window.localStorage.getItem(`aiosSetting${GlobalSettingDefinition[definition]}`) || '' : ''
export const setSetting = (definition: GlobalSettingDefinition, value: string | null) => window.localStorage.setItem(`aiosSetting${GlobalSettingDefinition[definition]}`, value || '')

export const getPasswordPolicy: (login: LoginHolder | null) => Promise<string> = login => {
    if (getPasswordPolicyExpiresAt().getTime() >= Date.now()) {
        return Promise.resolve<string>(getSetting(GlobalSettingDefinition.PASSWORD_POLICY))
    }
    return globalSettingAdapter.getValue(login, GlobalSettingDefinition[GlobalSettingDefinition.PASSWORD_POLICY])
            .then(response => {
                setSetting(GlobalSettingDefinition.PASSWORD_POLICY, response)
                setPasswordPolicyExpiresAt()
                return Promise.resolve<string>(response)
            })
            .catch(error => {
                console.error(error.message)
                return Promise.resolve<string>(getSetting(GlobalSettingDefinition.PASSWORD_POLICY))
            })
}
export const getPasswordHelp: (login: LoginHolder | null,) => Promise<string> = login => {
    if (getPasswordPolicyExpiresAt().getTime() >= Date.now()) {
        return Promise.resolve<string>(getSetting(GlobalSettingDefinition.PASSWORD_HELP))
    }
    return globalSettingAdapter.getValue(login, GlobalSettingDefinition[GlobalSettingDefinition.PASSWORD_HELP])
            .then(response => {
                setSetting(GlobalSettingDefinition.PASSWORD_HELP, response)
                setPasswordPolicyExpiresAt()
                return Promise.resolve<string>(response)
            })
            .catch(error => {
                console.error(error.message)
                return Promise.resolve<string>(getSetting(GlobalSettingDefinition.PASSWORD_HELP))
            })
}
export const getPasswordPolicyExpiresAt: () => Date = () => isBrowser && window.localStorage.getItem('aiosPasswordPolicyTimestamp') ? new Date(window.localStorage.getItem('aiosPasswordPolicyTimestamp') || '1970-01-01T00:00:00') : new Date('1970-01-01T00:00:00')
const setPasswordPolicyExpiresAt = () => {
    const expirationDate = new Date()
    expirationDate.setHours(expirationDate.getHours() + 3) // cache for 3 hours
    window.localStorage.setItem('aiosPasswordPolicyTimestamp', expirationDate.toISOString())
}
