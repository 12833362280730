/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TenantContractResponse
 */
export interface TenantContractResponse {
    /**
     * 
     * @type {string}
     * @memberof TenantContractResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TenantContractResponse
     */
    tenantId: string;
    /**
     * 
     * @type {Date}
     * @memberof TenantContractResponse
     */
    validFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof TenantContractResponse
     */
    validTo: Date;
    /**
     * 
     * @type {string}
     * @memberof TenantContractResponse
     */
    licenseType: string;
    /**
     * 
     * @type {string}
     * @memberof TenantContractResponse
     */
    licensePackage: string;
    /**
     * 
     * @type {number}
     * @memberof TenantContractResponse
     */
    metricsRetentionInHours: number;
    /**
     * 
     * @type {number}
     * @memberof TenantContractResponse
     */
    logsRetentionInHours: number;
    /**
     * 
     * @type {number}
     * @memberof TenantContractResponse
     */
    creditPrice: number;
    /**
     * 
     * @type {string}
     * @memberof TenantContractResponse
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof TenantContractResponse
     */
    supportPlan: string;
    /**
     * 
     * @type {string}
     * @memberof TenantContractResponse
     */
    supportResponseAgreement: string;
    /**
     * 
     * @type {string}
     * @memberof TenantContractResponse
     */
    supportResolutionAgreement: string;
    /**
     * 
     * @type {string}
     * @memberof TenantContractResponse
     */
    informationText?: string;
    /**
     * 
     * @type {string}
     * @memberof TenantContractResponse
     */
    informationType?: string;
    /**
     * 
     * @type {Date}
     * @memberof TenantContractResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof TenantContractResponse
     */
    updatedAt: Date;
}

export function TenantContractResponseFromJSON(json: any): TenantContractResponse {
    return TenantContractResponseFromJSONTyped(json, false);
}

export function TenantContractResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantContractResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tenantId': json['tenantId'],
        'validFrom': (new Date(json['validFrom'])),
        'validTo': (new Date(json['validTo'])),
        'licenseType': json['licenseType'],
        'licensePackage': json['licensePackage'],
        'metricsRetentionInHours': json['metricsRetentionInHours'],
        'logsRetentionInHours': json['logsRetentionInHours'],
        'creditPrice': json['creditPrice'],
        'currency': json['currency'],
        'supportPlan': json['supportPlan'],
        'supportResponseAgreement': json['supportResponseAgreement'],
        'supportResolutionAgreement': json['supportResolutionAgreement'],
        'informationText': !exists(json, 'informationText') ? undefined : json['informationText'],
        'informationType': !exists(json, 'informationType') ? undefined : json['informationType'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function TenantContractResponseToJSON(value?: TenantContractResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'validFrom': (value.validFrom.toISOString()),
        'validTo': (value.validTo.toISOString()),
        'licenseType': value.licenseType,
        'licensePackage': value.licensePackage,
        'metricsRetentionInHours': value.metricsRetentionInHours,
        'logsRetentionInHours': value.logsRetentionInHours,
        'creditPrice': value.creditPrice,
        'currency': value.currency,
        'supportPlan': value.supportPlan,
        'supportResponseAgreement': value.supportResponseAgreement,
        'supportResolutionAgreement': value.supportResolutionAgreement,
        'informationText': value.informationText,
        'informationType': value.informationType,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

