/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateTagRequest
 */
export interface CreateTagRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTagRequest
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTagRequest
     */
    name: string;
}

export function CreateTagRequestFromJSON(json: any): CreateTagRequest {
    return CreateTagRequestFromJSONTyped(json, false);
}

export function CreateTagRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTagRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tenantId': json['tenantId'],
        'name': json['name'],
    };
}

export function CreateTagRequestToJSON(value?: CreateTagRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tenantId': value.tenantId,
        'name': value.name,
    };
}

