/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreditTransactionResponse
 */
export interface CreditTransactionResponse {
    /**
     * 
     * @type {string}
     * @memberof CreditTransactionResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreditTransactionResponse
     */
    tenantId: string;
    /**
     * 
     * @type {number}
     * @memberof CreditTransactionResponse
     */
    credits: number;
    /**
     * 
     * @type {number}
     * @memberof CreditTransactionResponse
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof CreditTransactionResponse
     */
    type: CreditTransactionResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreditTransactionResponse
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreditTransactionResponse
     */
    userId?: string;
    /**
     * 
     * @type {Date}
     * @memberof CreditTransactionResponse
     */
    valuedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreditTransactionResponse
     */
    expiredAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreditTransactionResponse
     */
    createdAt: Date;
}

/**
* @export
* @enum {string}
*/
export enum CreditTransactionResponseTypeEnum {
    Deposit = 'DEPOSIT',
    Refund = 'REFUND',
    Usage = 'USAGE'
}

export function CreditTransactionResponseFromJSON(json: any): CreditTransactionResponse {
    return CreditTransactionResponseFromJSONTyped(json, false);
}

export function CreditTransactionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditTransactionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tenantId': json['tenantId'],
        'credits': json['credits'],
        'amount': json['amount'],
        'type': json['type'],
        'description': json['description'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'valuedAt': (new Date(json['valuedAt'])),
        'expiredAt': (new Date(json['expiredAt'])),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function CreditTransactionResponseToJSON(value?: CreditTransactionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'credits': value.credits,
        'amount': value.amount,
        'type': value.type,
        'description': value.description,
        'userId': value.userId,
        'valuedAt': (value.valuedAt.toISOString()),
        'expiredAt': (value.expiredAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
    };
}

