/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PermissionAction,
    PermissionActionFromJSON,
    PermissionActionFromJSONTyped,
    PermissionActionToJSON,
} from './PermissionAction';

/**
 * 
 * @export
 * @interface UserPermissionResponse
 */
export interface UserPermissionResponse {
    /**
     * 
     * @type {string}
     * @memberof UserPermissionResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserPermissionResponse
     */
    tenantId: string;
    /**
     * 
     * @type {string}
     * @memberof UserPermissionResponse
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UserPermissionResponse
     */
    entity: UserPermissionResponseEntityEnum;
    /**
     * 
     * @type {Array<PermissionAction>}
     * @memberof UserPermissionResponse
     */
    actions: Array<PermissionAction>;
    /**
     * 
     * @type {string}
     * @memberof UserPermissionResponse
     */
    resourceId?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserPermissionResponse
     */
    createdAt: Date;
}

/**
* @export
* @enum {string}
*/
export enum UserPermissionResponseEntityEnum {
    Setting = 'SETTING',
    Tenant = 'TENANT',
    Tenantcontract = 'TENANTCONTRACT',
    Tenantcontractdocument = 'TENANTCONTRACTDOCUMENT',
    Credittransaction = 'CREDITTRANSACTION',
    User = 'USER',
    Apikey = 'APIKEY',
    Service = 'SERVICE',
    Tag = 'TAG'
}

export function UserPermissionResponseFromJSON(json: any): UserPermissionResponse {
    return UserPermissionResponseFromJSONTyped(json, false);
}

export function UserPermissionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPermissionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tenantId': json['tenantId'],
        'userId': json['userId'],
        'entity': json['entity'],
        'actions': ((json['actions'] as Array<any>).map(PermissionActionFromJSON)),
        'resourceId': !exists(json, 'resourceId') ? undefined : json['resourceId'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function UserPermissionResponseToJSON(value?: UserPermissionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantId': value.tenantId,
        'userId': value.userId,
        'entity': value.entity,
        'actions': ((value.actions as Array<any>).map(PermissionActionToJSON)),
        'resourceId': value.resourceId,
        'createdAt': (value.createdAt.toISOString()),
    };
}

