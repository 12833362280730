/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateTagRequest,
    CreateTagRequestFromJSON,
    CreateTagRequestFromJSONTyped,
    CreateTagRequestToJSON,
} from './CreateTagRequest';

/**
 * 
 * @export
 * @interface CreateTagsRequest
 */
export interface CreateTagsRequest {
    /**
     * 
     * @type {Array<CreateTagRequest>}
     * @memberof CreateTagsRequest
     */
    elements: Array<CreateTagRequest>;
}

export function CreateTagsRequestFromJSON(json: any): CreateTagsRequest {
    return CreateTagsRequestFromJSONTyped(json, false);
}

export function CreateTagsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTagsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'elements': ((json['elements'] as Array<any>).map(CreateTagRequestFromJSON)),
    };
}

export function CreateTagsRequestToJSON(value?: CreateTagsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'elements': ((value.elements as Array<any>).map(CreateTagRequestToJSON)),
    };
}

