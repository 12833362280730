/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserPermissionResponse,
    UserPermissionResponseFromJSON,
    UserPermissionResponseFromJSONTyped,
    UserPermissionResponseToJSON,
} from './UserPermissionResponse';

/**
 * 
 * @export
 * @interface UserPermissionsResponse
 */
export interface UserPermissionsResponse {
    /**
     * 
     * @type {Array<UserPermissionResponse>}
     * @memberof UserPermissionsResponse
     */
    elements: Array<UserPermissionResponse>;
}

export function UserPermissionsResponseFromJSON(json: any): UserPermissionsResponse {
    return UserPermissionsResponseFromJSONTyped(json, false);
}

export function UserPermissionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPermissionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'elements': ((json['elements'] as Array<any>).map(UserPermissionResponseFromJSON)),
    };
}

export function UserPermissionsResponseToJSON(value?: UserPermissionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'elements': ((value.elements as Array<any>).map(UserPermissionResponseToJSON)),
    };
}

