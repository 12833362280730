/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ServiceSpecification,
    ServiceSpecificationFromJSON,
    ServiceSpecificationFromJSONTyped,
    ServiceSpecificationToJSON,
} from './ServiceSpecification';

/**
 * 
 * @export
 * @interface ServiceResponse
 */
export interface ServiceResponse {
    /**
     * 
     * @type {string}
     * @memberof ServiceResponse
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceResponse
     */
    version: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceResponse
     */
    tenantId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceResponse
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof ServiceResponse
     */
    alias: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceResponse
     */
    description?: string;
    /**
     * 
     * @type {ServiceSpecification}
     * @memberof ServiceResponse
     */
    specification: ServiceSpecification;
    /**
     * 
     * @type {string}
     * @memberof ServiceResponse
     */
    userId: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceResponse
     */
    costsPerRequest?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceResponse
     */
    referenceCostsPerRequest?: number;
    /**
     * 
     * @type {Date}
     * @memberof ServiceResponse
     */
    createdAt?: Date;
}

export function ServiceResponseFromJSON(json: any): ServiceResponse {
    return ServiceResponseFromJSONTyped(json, false);
}

export function ServiceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'],
        'tenantId': json['tenantId'],
        'enabled': json['enabled'],
        'alias': json['alias'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'specification': ServiceSpecificationFromJSON(json['specification']),
        'userId': json['userId'],
        'costsPerRequest': !exists(json, 'costsPerRequest') ? undefined : json['costsPerRequest'],
        'referenceCostsPerRequest': !exists(json, 'referenceCostsPerRequest') ? undefined : json['referenceCostsPerRequest'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
    };
}

export function ServiceResponseToJSON(value?: ServiceResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'version': value.version,
        'tenantId': value.tenantId,
        'enabled': value.enabled,
        'alias': value.alias,
        'name': value.name,
        'description': value.description,
        'specification': ServiceSpecificationToJSON(value.specification),
        'userId': value.userId,
        'costsPerRequest': value.costsPerRequest,
        'referenceCostsPerRequest': value.referenceCostsPerRequest,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}

