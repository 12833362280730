/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ServiceMetricsRangeResponseMetrics,
    ServiceMetricsRangeResponseMetricsFromJSON,
    ServiceMetricsRangeResponseMetricsFromJSONTyped,
    ServiceMetricsRangeResponseMetricsToJSON,
} from './ServiceMetricsRangeResponseMetrics';

/**
 * 
 * @export
 * @interface ServiceMetricsRangeResponse
 */
export interface ServiceMetricsRangeResponse {
    /**
     * 
     * @type {Array<ServiceMetricsRangeResponseMetrics>}
     * @memberof ServiceMetricsRangeResponse
     */
    metrics: Array<ServiceMetricsRangeResponseMetrics>;
}

export function ServiceMetricsRangeResponseFromJSON(json: any): ServiceMetricsRangeResponse {
    return ServiceMetricsRangeResponseFromJSONTyped(json, false);
}

export function ServiceMetricsRangeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceMetricsRangeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metrics': ((json['metrics'] as Array<any>).map(ServiceMetricsRangeResponseMetricsFromJSON)),
    };
}

export function ServiceMetricsRangeResponseToJSON(value?: ServiceMetricsRangeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metrics': ((value.metrics as Array<any>).map(ServiceMetricsRangeResponseMetricsToJSON)),
    };
}

