import {randomBytes} from "crypto";
import {GridFilterModel, GridSortItem} from "@mui/x-data-grid";
import {LoginHolder} from "../components/provider/LoginProvider";

export const isBrowser: boolean = typeof window !== 'undefined'

const utf8TextEncoder = new TextEncoder()
const utf8TextDecoder = new TextDecoder()

export const displayNameOf = (email: string, firstname: string | null, lastname: string | null): string => {
    if (firstname && lastname) return `${firstname} ${lastname}`
    if (firstname) return firstname
    if (lastname) return lastname
    return email
}

export const displayName = (login: LoginHolder | null): string => {
    if (!login) return ''
    return displayNameOf(login.email, login.firstname, login.lastname)
}

export const initials = (login: LoginHolder | null): string | null => {
    if (!login) return null
    if (login.firstname && login.lastname) return `${login.firstname.charAt(0)}${login.lastname.charAt(0)}`
    if (login.firstname) return login.firstname.charAt(0)
    if (login.lastname) return login.lastname.charAt(0)
    return login.email.charAt(0)
}

export function getLocalStorageString(key: string): string | null {
    if (!isBrowser) return null // needed for build
    return window.localStorage.getItem(key) ? window.localStorage.getItem(key)! : null
}

export function getLocalStorageObject(key: string): any | null {
    if (!isBrowser) return null // needed for build
    return window.localStorage.getItem(key) ? JSON.parse(window.localStorage.getItem(key)!) : null
}

export function setLocalStorageObject(key: string, value: any | null) {
    if (!isBrowser) return null // needed for build
    if (value) window.localStorage.setItem(key, JSON.stringify(value))
    else window.localStorage.removeItem(key)
}

export const assembleSort = (sortModel: GridSortItem[]): string[] => {
    return sortModel
            .map(item => `${encodeURIComponent(item.sort === "desc" ? "-" : "+")}${item.field}`)
}

export const assembleFilter = (filterModel: GridFilterModel): string => {
    return filterModel
            .items
            .map(item => {
                if (item.operator === "equals") {
                    return `${item.field}=="${item.value ? item.value : ""}"`
                } else if (item.operator === "is") {
                    if (item.value === "true" || item.value === "false") {
                        return `${item.field}==${item.value}`
                    }
                    return ""
                }
                return ""
            }).join(";")
}

export const randomString = (length: number = 8): string => {
    return randomBytes(length).toString('hex').substring(0, length)
}

export const groupBy = <V, K extends keyof any>(array: V[], key: (item: V) => K) =>
        array.reduce((previousValue, currentValue) => {
            const groupKey = key(currentValue)
            if (!previousValue[groupKey]) previousValue[groupKey] = []
            previousValue[groupKey].push(currentValue)
            return previousValue
        }, {} as Record<K, V[]>)

export const base64urlEncode = (string: string) => btoa(string.replace(/\+/g, '-').replace(/\//g, '_')).replace(/\=+$/m, '')
export const base64urlDecode = (string: string) => atob(string.replace(/\-/g, '+').replace(/_/g, '/'))
export const base64EncodeUtf8Safe = (string: string): string => {
    const bytes = utf8TextEncoder.encode(string)
    return btoa(String.fromCharCode(...bytes))
}
export const base64DecodeUtf8Safe = (string: string): string => {
    const decodedBase64 = atob(string)
    let bytes = []
    for (let i = 0; i < decodedBase64.length; i++) {
        bytes[i] = decodedBase64.charCodeAt(i)
    }
    return utf8TextDecoder.decode(new Uint8Array(bytes))
}
