import {CreateAdapter, DeleteAdapter, FindAdapter, GetAdapter, Page, Pageable, UpdateAdapter, UUID} from "./interfaces";
import {LoginHolder} from "../components/provider/LoginProvider";
import {apiDelete, apiGet, apiPost, apiPut} from "../misc/api";
import {PageFromJSON} from "../generated/models/Page";
import {ServiceResponse, ServiceResponseFromJSON} from "../generated/models/ServiceResponse";
import {CreateServiceRequest} from "../generated/models/CreateServiceRequest";
import {UpdateServiceRequest} from "../generated/models/UpdateServiceRequest";
import {ServiceSpecification} from "../generated/models/ServiceSpecification";
import {ServiceEnvironment} from "../generated/models/ServiceEnvironment";
import {ServiceReachability} from "../generated/models/ServiceReachability";
import {ServiceImage} from "../generated/models/ServiceImage";
import {ServiceResources} from "../generated/models/ServiceResources";
import {ServiceLabel} from "../generated/models/ServiceLabel";
import {ServiceVariable} from "../generated/models/ServiceVariable";
import {ServiceFile} from "../generated/models/ServiceFile";
import {ServiceTelemetry} from "../generated/models/ServiceTelemetry";
import {ServiceStatus, ServiceStatusFromJSON} from "../generated/models/ServiceStatus";
import {ServiceMount} from "../generated/models/ServiceMount";
import {ServiceEndpoint} from "../generated/models/ServiceEndpoint";
import {CreateTagServicesRequest} from "../generated/models/CreateTagServicesRequest";
import {TagServicesResponse, TagServicesResponseFromJSON} from "../generated/models/TagServicesResponse";
import {DeleteTagServicesRequest} from "../generated/models/DeleteTagServicesRequest";
import {TagServiceResponse} from "../generated/models/TagServiceResponse";
import {ServiceLogsRangeResponse, ServiceLogsRangeResponseFromJSON} from "../generated/models/ServiceLogsRangeResponse";
import moment from "moment";

export class ServiceAdapter implements CreateAdapter<UUID, ServiceResponse>, UpdateAdapter<UUID, ServiceResponse>, FindAdapter<UUID, ServiceResponse>, GetAdapter<UUID, ServiceResponse>, DeleteAdapter<UUID, ServiceResponse> {
    private readonly base: string = `/services`
    private readonly baseTagConnections: string = `/tag-service-connections`

    create(login: LoginHolder | null, elements: any[]): Promise<ServiceResponse[]> {
        return Promise.reject("Not implemented")
    }

    createOne(login: LoginHolder | null, element: CreateServiceRequest): Promise<ServiceResponse> {
        return apiPost(login, this.base, element)
                .then(response => {
                    console.debug("Successfully created element", response)
                    return ServiceResponseFromJSON(response)
                })
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<ServiceResponse>> {
        return apiGet(login, `${this.base}?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<ServiceResponse>
                })
    }

    findVersions(login: LoginHolder | null, id: UUID, request: Pageable): Promise<Page<ServiceResponse>> {
        return apiGet(login, `${this.base}/${id}/versions?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<ServiceResponse>
                })
    }

    get(login: LoginHolder | null, id: UUID): Promise<ServiceResponse | null> {
        return apiGet(login, `${this.base}/${id}`)
                .then(response => {
                    console.debug("Successfully retrieved element", response)
                    return ServiceResponseFromJSON(response)
                })
    }

    getLogs(login: LoginHolder | null, id: UUID, start?: Date, end?: Date, filter?: string, filterType?: "text" | "regex", sortOrder?: "asc" | "desc", limit?: number): Promise<ServiceLogsRangeResponse | null> {
        const startParam = start ? `start=${moment(start).toISOString()}` : ""
        const endParam = end ? `end=${moment(end).toISOString()}` : ""
        const filterParam = filter ? `filter=${encodeURIComponent(filter)}` : ""
        const filterTypeParam = filterType ? `filterType=${filterType}` : ""
        const sortOrderParam = sortOrder ? `sortOrder=${sortOrder}` : ""
        const limitParam = limit ? `limit=${limit}` : ""
        const queryString = [startParam, endParam, filterParam, filterTypeParam, sortOrderParam, limitParam].filter(s => s.length != 0).join("&")

        return apiGet(login, `${this.base}/${id}/logs${queryString ? "?" + queryString : ""}`)
                .then(response => {
                    console.debug("Successfully retrieved element logs", response)
                    return ServiceLogsRangeResponseFromJSON(response)
                })
    }

    update(login: LoginHolder | null, elements: UpdateServiceRequest[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOne(login: LoginHolder | null, element: UpdateServiceRequest): Promise<void> {
        return apiPut(login, `${this.base}/${element.id}`, element)
                .then(() => console.debug("Successfully updated element"))
    }

    delete(login: LoginHolder | null, ids: UUID[]): Promise<void> {
        return apiDelete(login, `${this.base}/batch`, {ids: ids})
                .then(response => {
                    console.debug("Successfully deleted elements", response)
                })
    }

    deleteOne(login: LoginHolder | null, id: UUID): Promise<void> {
        return apiDelete(login, `${this.base}/${id}`)
                .then(() => {
                    console.debug("Successfully deleted element", id)
                })
    }

    status(login: LoginHolder | null, id: UUID): Promise<ServiceStatus> {
        return apiGet(login, `${this.base}/${id}/status`)
                .then(response => {
                    console.debug("Retrieved status for {}", response)
                    return ServiceStatusFromJSON(response)
                })
    }

    //<editor-fold desc="Tags">
    addTags(login: LoginHolder | null, elements: CreateTagServicesRequest): Promise<TagServicesResponse> {
        return apiPost(login, `${this.baseTagConnections}/batch`, elements)
                .then(response => {
                    console.debug("Successfully added elements", response)
                    return TagServicesResponseFromJSON(response)
                })
    }

    removeTags(login: LoginHolder | null, elements: DeleteTagServicesRequest): Promise<void> {
        return apiDelete(login, `${this.baseTagConnections}/batch`, elements)
                .then(response => {
                    console.debug("Successfully removed elements", response)
                })
    }

    findTags(login: LoginHolder | null, request: Pageable): Promise<Page<TagServiceResponse>> {
        return apiGet(login, `${this.baseTagConnections}?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<TagServiceResponse>
                })
    }

    //</editor-fold>

}

export class ServiceSpecificationImpl implements ServiceSpecification {
    image: ServiceImage = new ServiceImageImpl();
    resources: ServiceResources = new ServiceResourcesImpl();
    environment: ServiceEnvironment = new ServiceEnvironmentImpl();
    endpoints: ServiceEndpoint[] = [];
    telemetry: ServiceTelemetry = new ServiceTelemetryImpl();
    reachability: ServiceReachability = new ServiceReachabilityImpl();
    tags: Array<string> = [];
}

export class ServiceImageImpl implements ServiceImage {
    name: string = "";
    tag: string = "";
    ports: Array<number> = [];
}

export class ServiceResourcesImpl implements ServiceResources {
    replicas: number = 1;
    cpuMaxInMilliseconds: number = 1;
    cpuMinInMilliseconds: number = 1;
    memoryMaxInMegaBytes: number = 1;
    memoryMinInMegaBytes: number = 1;
    storageInMegaBytes: number = 0;
    gpu: number = 0;
    runSelectors: Array<ServiceLabel> = [];
    runRestrictions: Array<ServiceLabel> = [];
}

export class ServiceEnvironmentImpl implements ServiceEnvironment {
    configFiles: Array<ServiceFile> = [];
    variables: Array<ServiceVariable> = [];
    mounts: Array<ServiceMount> = [];
    labels: Array<ServiceLabel> = [];
}

export class ServiceTelemetryImpl implements ServiceTelemetry {
    metricsToRecordAsRegex: string[] = [];
}

export class ServiceReachabilityImpl implements ServiceReachability {
    domain: string = "";
    domainUseTls: boolean = true;
    requestTimeoutInSeconds: number = 5;
}
