/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TenantContractDocumentResponse
 */
export interface TenantContractDocumentResponse {
    /**
     * 
     * @type {string}
     * @memberof TenantContractDocumentResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TenantContractDocumentResponse
     */
    tenantContractId: string;
    /**
     * 
     * @type {string}
     * @memberof TenantContractDocumentResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TenantContractDocumentResponse
     */
    mimeType: string;
    /**
     * 
     * @type {Date}
     * @memberof TenantContractDocumentResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof TenantContractDocumentResponse
     */
    updatedAt: Date;
}

export function TenantContractDocumentResponseFromJSON(json: any): TenantContractDocumentResponse {
    return TenantContractDocumentResponseFromJSONTyped(json, false);
}

export function TenantContractDocumentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantContractDocumentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tenantContractId': json['tenantContractId'],
        'name': json['name'],
        'mimeType': json['mimeType'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function TenantContractDocumentResponseToJSON(value?: TenantContractDocumentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tenantContractId': value.tenantContractId,
        'name': value.name,
        'mimeType': value.mimeType,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

