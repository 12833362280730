/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TagResponse,
    TagResponseFromJSON,
    TagResponseFromJSONTyped,
    TagResponseToJSON,
} from './TagResponse';

/**
 * 
 * @export
 * @interface TagsResponse
 */
export interface TagsResponse {
    /**
     * 
     * @type {Array<TagResponse>}
     * @memberof TagsResponse
     */
    elements: Array<TagResponse>;
}

export function TagsResponseFromJSON(json: any): TagsResponse {
    return TagsResponseFromJSONTyped(json, false);
}

export function TagsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'elements': ((json['elements'] as Array<any>).map(TagResponseFromJSON)),
    };
}

export function TagsResponseToJSON(value?: TagsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'elements': ((value.elements as Array<any>).map(TagResponseToJSON)),
    };
}

