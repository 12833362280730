import {apiDelete, apiGet, apiPost} from "../misc/api";
import {LoginUserRequest} from "../generated/models/LoginUserRequest";
import {LoginUserResponse, LoginUserResponseFromJSON} from "../generated/models/LoginUserResponse";
import {LoginHolder} from "../components/provider/LoginProvider";
import {UserPermissionResponse} from "../generated/models/UserPermissionResponse";
import {Page, Pageable, UUID} from "./interfaces";
import {PageFromJSON} from "../generated/models/Page";
import {ApiKeyPermissionResponse} from "../generated/models/ApiKeyPermissionResponse";
import {CreateUserPermissionsRequest} from "../generated/models/CreateUserPermissionsRequest";
import {CreateApiKeyPermissionsRequest} from "../generated/models/CreateApiKeyPermissionsRequest";
import {
    ApiKeyPermissionsResponse,
    ApiKeyPermissionsResponseFromJSON
} from "../generated/models/ApiKeyPermissionsResponse";
import {UserPermissionsResponse, UserPermissionsResponseFromJSON} from "../generated/models/UserPermissionsResponse";

export class AuthAdapter {

    login(element: LoginUserRequest): Promise<LoginUserResponse> {
        return apiPost(null, `/login`, element, {}, false)
                .then(response => {
                    console.debug("Successfully logged in", response)
                    return LoginUserResponseFromJSON(response)
                })
    }

    logout(login: LoginHolder | null): Promise<any> {
        return apiGet(login, `/logout`)
    }

    createUserPermissions(login: LoginHolder | null, permissions: CreateUserPermissionsRequest): Promise<UserPermissionsResponse> {
        return apiPost(login, `/user-permission-connections/batch`, permissions)
                .then(response => {
                    console.debug("Successfully created elements", response)
                    return UserPermissionsResponseFromJSON(response)
                })
    }

    deleteUserPermissions(login: LoginHolder | null, ids: UUID[]): Promise<void> {
        return apiDelete(login, `/user-permission-connections/batch`, {ids: ids})
                .then(response => {
                    console.debug("Successfully deleted elements", response)
                })
    }

    findUserPermissions(login: LoginHolder | null, request: Pageable): Promise<Page<UserPermissionResponse>> {
        return apiGet(login, `/user-permission-connections?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<UserPermissionResponse>
                })
    }

    createApiKeyPermissions(login: LoginHolder | null, permissions: CreateApiKeyPermissionsRequest): Promise<ApiKeyPermissionsResponse> {
        return apiPost(login, `/api-key-permission-connections/batch`, permissions)
                .then(response => {
                    console.debug("Successfully created elements", response)
                    return ApiKeyPermissionsResponseFromJSON(response)
                })
    }

    deleteApiKeyPermissions(login: LoginHolder | null, ids: UUID[]): Promise<void> {
        return apiDelete(login, `/api-key-permission-connections/batch`, {ids: ids})
                .then(response => {
                    console.debug("Successfully deleted elements", response)
                })
    }

    findApiKeyPermissions(login: LoginHolder | null, request: Pageable): Promise<Page<ApiKeyPermissionResponse>> {
        return apiGet(login, `/api-key-permission-connections?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<ApiKeyPermissionResponse>
                })
    }

}
