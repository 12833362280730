/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ServiceFile,
    ServiceFileFromJSON,
    ServiceFileFromJSONTyped,
    ServiceFileToJSON,
} from './ServiceFile';
import {
    ServiceLabel,
    ServiceLabelFromJSON,
    ServiceLabelFromJSONTyped,
    ServiceLabelToJSON,
} from './ServiceLabel';
import {
    ServiceMount,
    ServiceMountFromJSON,
    ServiceMountFromJSONTyped,
    ServiceMountToJSON,
} from './ServiceMount';
import {
    ServiceVariable,
    ServiceVariableFromJSON,
    ServiceVariableFromJSONTyped,
    ServiceVariableToJSON,
} from './ServiceVariable';

/**
 * 
 * @export
 * @interface ServiceEnvironment
 */
export interface ServiceEnvironment {
    /**
     * 
     * @type {Array<ServiceVariable>}
     * @memberof ServiceEnvironment
     */
    variables: Array<ServiceVariable>;
    /**
     * 
     * @type {Array<ServiceFile>}
     * @memberof ServiceEnvironment
     */
    configFiles: Array<ServiceFile>;
    /**
     * 
     * @type {Array<ServiceMount>}
     * @memberof ServiceEnvironment
     */
    mounts: Array<ServiceMount>;
    /**
     * 
     * @type {Array<ServiceLabel>}
     * @memberof ServiceEnvironment
     */
    labels: Array<ServiceLabel>;
}

export function ServiceEnvironmentFromJSON(json: any): ServiceEnvironment {
    return ServiceEnvironmentFromJSONTyped(json, false);
}

export function ServiceEnvironmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceEnvironment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'variables': ((json['variables'] as Array<any>).map(ServiceVariableFromJSON)),
        'configFiles': ((json['configFiles'] as Array<any>).map(ServiceFileFromJSON)),
        'mounts': ((json['mounts'] as Array<any>).map(ServiceMountFromJSON)),
        'labels': ((json['labels'] as Array<any>).map(ServiceLabelFromJSON)),
    };
}

export function ServiceEnvironmentToJSON(value?: ServiceEnvironment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'variables': ((value.variables as Array<any>).map(ServiceVariableToJSON)),
        'configFiles': ((value.configFiles as Array<any>).map(ServiceFileToJSON)),
        'mounts': ((value.mounts as Array<any>).map(ServiceMountToJSON)),
        'labels': ((value.labels as Array<any>).map(ServiceLabelToJSON)),
    };
}

