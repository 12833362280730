/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ServiceLogsRangeResponseLogs,
    ServiceLogsRangeResponseLogsFromJSON,
    ServiceLogsRangeResponseLogsFromJSONTyped,
    ServiceLogsRangeResponseLogsToJSON,
} from './ServiceLogsRangeResponseLogs';

/**
 * 
 * @export
 * @interface ServiceLogsRangeResponse
 */
export interface ServiceLogsRangeResponse {
    /**
     * 
     * @type {Array<ServiceLogsRangeResponseLogs>}
     * @memberof ServiceLogsRangeResponse
     */
    logs: Array<ServiceLogsRangeResponseLogs>;
}

export function ServiceLogsRangeResponseFromJSON(json: any): ServiceLogsRangeResponse {
    return ServiceLogsRangeResponseFromJSONTyped(json, false);
}

export function ServiceLogsRangeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceLogsRangeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'logs': ((json['logs'] as Array<any>).map(ServiceLogsRangeResponseLogsFromJSON)),
    };
}

export function ServiceLogsRangeResponseToJSON(value?: ServiceLogsRangeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'logs': ((value.logs as Array<any>).map(ServiceLogsRangeResponseLogsToJSON)),
    };
}

