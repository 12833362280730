import {Page, Pageable, UUID} from "./interfaces";
import {LoginHolder} from "../components/provider/LoginProvider";
import {apiGet, apiPost} from "../misc/api";
import {PageFromJSON} from "../generated/models/Page";
import {AbstractAdapter} from "./AbstractAdapter";
import {
    CreditTransactionResponse,
    CreditTransactionResponseFromJSON
} from "../generated/models/CreditTransactionResponse";
import {CreateCreditTransactionRequest} from "../generated/models/CreateCreditTransactionRequest";
import {CreateCreditTransactionsRequestFromJSON} from "../generated/models/CreateCreditTransactionsRequest";
import {CreditTransactionsResponseFromJSON} from "../generated/models/CreditTransactionsResponse";

export class CreditTransactionAdapter extends AbstractAdapter<UUID, CreditTransactionResponse> {
    private readonly base: string = `/credit-transactions`

    protected basePath(): string {
        return this.base;
    }

    create(login: LoginHolder | null, elements: CreateCreditTransactionRequest[]): Promise<CreditTransactionResponse[]> {
        return apiPost(login, `${this.base}/batch`, CreateCreditTransactionsRequestFromJSON({elements}))
                .then(response => {
                    console.debug("Successfully created elements", response)
                    return CreditTransactionsResponseFromJSON(response).elements
                })
    }

    createOne(login: LoginHolder | null, element: CreateCreditTransactionRequest): Promise<CreditTransactionResponse> {
        return apiPost(login, this.base, element)
                .then(response => {
                    console.debug("Successfully created element", response)
                    return CreditTransactionResponseFromJSON(response)
                })
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<CreditTransactionResponse>> {
        return apiGet(login, `${this.base}?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<CreditTransactionResponse>
                })
    }

    get(login: LoginHolder | null, id: UUID): Promise<CreditTransactionResponse | null> {
        return apiGet(login, `${this.base}/${id}`)
                .then(response => {
                    console.debug("Successfully retrieved element", response)
                    return CreditTransactionResponseFromJSON(response)
                })
    }

    update(login: LoginHolder | null, elements: any[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOne(login: LoginHolder | null, element: any): Promise<void> {
        return Promise.reject("Not implemented")
    }

}
