import {Page, Pageable, UUID} from "./interfaces";
import {LoginHolder} from "../components/provider/LoginProvider";
import {apiGet, apiPost, apiPut} from "../misc/api";
import {PageFromJSON} from "../generated/models/Page";
import {AbstractAdapter} from "./AbstractAdapter";
import {TagResponse, TagResponseFromJSON} from "../generated/models/TagResponse";
import {UpdateTagRequest} from "../generated/models/UpdateTagRequest";
import {CreateTagRequest} from "../generated/models/CreateTagRequest";
import {CreateTagsRequestFromJSON} from "../generated/models/CreateTagsRequest";
import {TagsResponseFromJSON} from "../generated/models/TagsResponse";

export class TagAdapter extends AbstractAdapter<UUID, TagResponse> {
    private readonly base: string = `/tags`

    protected basePath(): string {
        return this.base;
    }

    create(login: LoginHolder | null, elements: CreateTagRequest[]): Promise<TagResponse[]> {
        return apiPost(login, `${this.base}/batch`, CreateTagsRequestFromJSON({elements}))
                .then(response => {
                    console.debug("Successfully created elements", response)
                    return TagsResponseFromJSON(response).elements
                })
    }

    createOne(login: LoginHolder | null, element: CreateTagRequest): Promise<TagResponse> {
        return apiPost(login, this.base, element)
                .then(response => {
                    console.debug("Successfully created element", response)
                    return TagResponseFromJSON(response)
                })
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<TagResponse>> {
        return apiGet(login, `${this.base}?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<TagResponse>
                })
    }

    get(login: LoginHolder | null, id: UUID): Promise<TagResponse | null> {
        return apiGet(login, `${this.base}/${id}`)
                .then(response => {
                    console.debug("Successfully retrieved element", response)
                    return TagResponseFromJSON(response)
                })
    }

    update(login: LoginHolder | null, elements: UpdateTagRequest[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOne(login: LoginHolder | null, element: UpdateTagRequest): Promise<void> {
        return apiPut(login, `${this.base}/${element.id}`, element)
                .then(() => console.debug("Successfully updated element"))
    }

}
