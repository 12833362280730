/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceReachability
 */
export interface ServiceReachability {
    /**
     * 
     * @type {string}
     * @memberof ServiceReachability
     */
    domain?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceReachability
     */
    domainUseTls?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ServiceReachability
     */
    domainTlsCertFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceReachability
     */
    domainTlsKeyFileName?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceReachability
     */
    requestTimeoutInSeconds: number;
}

export function ServiceReachabilityFromJSON(json: any): ServiceReachability {
    return ServiceReachabilityFromJSONTyped(json, false);
}

export function ServiceReachabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceReachability {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'domainUseTls': !exists(json, 'domainUseTls') ? undefined : json['domainUseTls'],
        'domainTlsCertFileName': !exists(json, 'domainTlsCertFileName') ? undefined : json['domainTlsCertFileName'],
        'domainTlsKeyFileName': !exists(json, 'domainTlsKeyFileName') ? undefined : json['domainTlsKeyFileName'],
        'requestTimeoutInSeconds': json['requestTimeoutInSeconds'],
    };
}

export function ServiceReachabilityToJSON(value?: ServiceReachability | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domain': value.domain,
        'domainUseTls': value.domainUseTls,
        'domainTlsCertFileName': value.domainTlsCertFileName,
        'domainTlsKeyFileName': value.domainTlsKeyFileName,
        'requestTimeoutInSeconds': value.requestTimeoutInSeconds,
    };
}

