import {Adapter, Page, Pageable, UUID} from "./interfaces";
import {LoginHolder} from "../components/provider/LoginProvider";
import {api, apiDelete, apiGet, apiPost, apiPut} from "../misc/api";
import {PageFromJSON} from "../generated/models/Page";
import {CreateTenantContractRequest} from "../generated/models/CreateTenantContractRequest";
import {TenantContractResponse, TenantContractResponseFromJSON} from "../generated/models/TenantContractResponse";
import {UpdateTenantContractRequest} from "../generated/models/UpdateTenantContractRequest";
import {
    TenantContractDocumentResponse,
    TenantContractDocumentResponseFromJSON
} from "../generated/models/TenantContractDocumentResponse";
import {CreateTenantContractDocumentRequest} from "../generated/models/CreateTenantContractDocumentRequest";
import {UpdateTenantContractDocumentRequest} from "../generated/models/UpdateTenantContractDocumentRequest";

export class TenantContractAdapter implements Adapter<UUID, TenantContractResponse> {
    private readonly base: string = `/tenant-contracts`
    private readonly baseDocuments: string = `/tenant-contract-documents`

    create(login: LoginHolder | null, elements: any[]): Promise<TenantContractResponse[]> {
        return Promise.reject("Not implemented")
    }

    createOne(login: LoginHolder | null, element: CreateTenantContractRequest): Promise<TenantContractResponse> {
        return apiPost(login, this.base, element)
                .then(response => {
                    console.debug("Successfully created element", response)
                    return TenantContractResponseFromJSON(response)
                })
    }

    createDocument(login: LoginHolder | null, element: CreateTenantContractDocumentRequest): Promise<TenantContractDocumentResponse> {
        return apiPost(login, this.baseDocuments, element)
                .then(response => {
                    console.debug("Successfully created element", response)
                    return TenantContractDocumentResponseFromJSON(response)
                })
    }

    find(login: LoginHolder | null, request: Pageable): Promise<Page<TenantContractResponse>> {
        return apiGet(login, `${this.base}?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<TenantContractResponse>
                })
    }

    findDocuments(login: LoginHolder | null, request: Pageable): Promise<Page<TenantContractDocumentResponse>> {
        return apiGet(login, `${this.baseDocuments}?${request.toQueryString()}`)
                .then(response => {
                    console.debug("Successfully retrieved elements", response)
                    return PageFromJSON(response) as Page<TenantContractDocumentResponse>
                })
    }

    get(login: LoginHolder | null, id: UUID): Promise<TenantContractResponse | null> {
        return apiGet(login, `${this.base}/${id}`)
                .then(response => {
                    console.debug("Successfully retrieved element", response)
                    return TenantContractResponseFromJSON(response)
                })
    }

    getDocumentData(login: LoginHolder | null, element: TenantContractDocumentResponse): Promise<Blob | null> {
        return api(login, "GET", `${this.baseDocuments}/${element.id}/data`, element.mimeType, null, {}, true, process.env.GATSBY_API_URL || "", true)
                .then(response => {
                    console.debug("Successfully retrieved element", response)
                    return response.blob()
                })
    }

    update(login: LoginHolder | null, elements: any[]): Promise<void> {
        return Promise.reject("Not implemented")
    }

    updateOne(login: LoginHolder | null, element: UpdateTenantContractRequest): Promise<void> {
        return apiPut(login, `${this.base}/${element.id}`, element)
                .then(() => console.debug("Successfully updated element"))
    }

    updateDocument(login: LoginHolder | null, element: UpdateTenantContractDocumentRequest): Promise<void> {
        return apiPut(login, `${this.baseDocuments}/${element.id}`, element)
                .then(() => console.debug("Successfully updated element"))
    }

    delete(login: LoginHolder | null, ids: UUID[]): Promise<void> {
        return apiDelete(login, `${this.base}/batch`, {
            ids: ids
        })
                .then(response => {
                    console.debug("Successfully deleted elements", response)
                })
    }

    deleteOne(login: LoginHolder | null, id: UUID): Promise<void> {
        return apiDelete(login, `${this.base}/${id}`)
                .then(() => {
                    console.debug("Successfully deleted element", id)
                })
    }

    deleteDocuments(login: LoginHolder | null, ids: UUID[]): Promise<void> {
        return apiDelete(login, `${this.baseDocuments}/batch`, {ids: ids})
                .then(response => {
                    console.debug("Successfully deleted elements", response)
                })
    }

}
