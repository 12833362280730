/* tslint:disable */
/* eslint-disable */
/**
 * AIOS HTTP API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ServiceInitImage,
    ServiceInitImageFromJSON,
    ServiceInitImageFromJSONTyped,
    ServiceInitImageToJSON,
} from './ServiceInitImage';

/**
 * 
 * @export
 * @interface ServiceImage
 */
export interface ServiceImage {
    /**
     * 
     * @type {string}
     * @memberof ServiceImage
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceImage
     */
    tag: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceImage
     */
    command?: Array<string>;
    /**
     * 
     * @type {ServiceInitImage}
     * @memberof ServiceImage
     */
    initializer?: ServiceInitImage;
}

export function ServiceImageFromJSON(json: any): ServiceImage {
    return ServiceImageFromJSONTyped(json, false);
}

export function ServiceImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'tag': json['tag'],
        'command': !exists(json, 'command') ? undefined : json['command'],
        'initializer': !exists(json, 'initializer') ? undefined : ServiceInitImageFromJSON(json['initializer']),
    };
}

export function ServiceImageToJSON(value?: ServiceImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'tag': value.tag,
        'command': value.command,
        'initializer': ServiceInitImageToJSON(value.initializer),
    };
}

